require('./bootstrap');
import 'jquery-ui/ui/widgets/autocomplete.js';
import 'jquery-ui/ui/widgets/datepicker.js';
import 'jquery-ui/ui/i18n/datepicker-hr.js';
import 'jquery-ui/themes/base/all.css';

// kalendar
var options = $.extend(
  $.datepicker.regional["hr"],
  {
    dateFormat: "dd.mm.yy.",
    changeYear: true,
    changeMonth: true,
    showButtonPanel: true,
    yearRange: "1900:2100",
  }
);

$(document).ready(function(){
  $('.datepicker').datepicker(options);
});

$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})